import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import theme from '../utils/theme'

import Layout from '../layout/Layout'
import Seo from '../components/Seo'
import { Services, Profiles } from '../sections/index'

import hero from '../images/banners/allency-banner-services.png'
import SmallHeroBand from '../sections/SmallHeroBand'
import { MediumBand } from '../sections/BandSection'

import branding from '../images/icons/service-branding.svg'
import strategy from '../images/icons/service-strategy.svg'
import webDevelopment from '../images/icons/service-web-development.svg'
import mktDigital from '../images/icons/service-mkt-digital.svg'

const IndexPage = () => {
  const dataImage = useStaticQuery(graphql`
    query {
      serviceOne: file(relativePath: { eq: "stock/allency-services-branding.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      serviceTwo: file(relativePath: { eq: "stock/allency-services-advertising.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      serviceThree: file(relativePath: { eq: "stock/allency-services-web-sites.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      serviceFour: file(relativePath: { eq: "stock/allency-services-digital-marketing.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }


      profileOne: file(relativePath: { eq: "stock/allency-pymes.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      profileTwo: file(relativePath: { eq: "stock/allency-business.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      profileThree: file(relativePath: { eq: "stock/allency-events.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)

  const serviceOne = dataImage.serviceOne.childImageSharp.gatsbyImageData
  const serviceTwo = dataImage.serviceTwo.childImageSharp.gatsbyImageData
  const serviceThree = dataImage.serviceThree.childImageSharp.gatsbyImageData
  const serviceFour = dataImage.serviceFour.childImageSharp.gatsbyImageData

  const profileOne = dataImage.profileOne.childImageSharp.gatsbyImageData
  const profileTwo = dataImage.profileTwo.childImageSharp.gatsbyImageData
  const profileThree = dataImage.profileThree.childImageSharp.gatsbyImageData

  const contents = {
    hero: {
      image: hero,
      title: 'Servicios',
    },
    services: {
      items: [
        {
          image: serviceOne,
          alt: 'Branding e imagen',
          icon: branding,
          title: 'Branding e imagen',
          description: `
            Es el proceso más importante para la construcción de la identidad, imagen y comunicación de tu marca.<br/> 
            Revisamos los servicios claves del branding; desde la generación del naming y logotipo, hasta la creación del manual de identidad y materiales de comunicación. `,
          list: ['Creación de logotipo','Manual de identidad','Brochure','Papelería institucional','Presentaciones corporativas']
        },
        {
          image: serviceTwo,
          alt: 'Publicidad estratégica',
          icon: strategy,
          title: 'Publicidad estratégica',
          description: `Gran parte de las ventas en un negocio son gracias a una buena estrategia publicitaria y su ejecución. Nosotros te apoyaremos en definir qué tipo de estrategia se puede llevar a la acción de acuerdo a tu tipo de proyecto y presupuesto.`,
          list: ['Concepto creativo','Planeación y ejecución','Selección de medios','Publicidad ATL y BTL','Campañas 360']
        },
        {
          image: serviceThree,
          alt: 'Desarrollo web',
          icon: webDevelopment,
          title: 'Desarrollo web',
          description: `
            Realizamos desde el esqueleto de tu sitio hasta la generación del diseño web y programación. <br/> 
            Nos encargaremos de que tu sitio web vaya de acuerdo a las necesidades de tu negocio. Algunas de las opciones pueden ser landing pages, e-commerces y sitios webs corporativos.`,
          list: ['Sitios web y tiendas en línea','Código con tecnología gatsby y react js','Diseño web responsivo','Posicionamiento y tráfico','Experiencia e interfaces de usuario']
        },
        {
          image: serviceFour,
          alt: 'Marketing digital',
          icon: mktDigital,
          title: 'Marketing digital',
          description: `
            Generamos estrategias digitales para tu marca a través de campañas en diferentes plataformas, generación de contenido para tu audiencia en redes sociales, creación de material de comunicación digital y reportes de desempeño.`,
          list: ['Campañas publicitarias','Reportes de rendimiento','Posicionamiento SEO','Gestión de redes sociales','Multimedia']
        },
      ]
    },
    profiles: {
      items: [
        {image: profileOne, alt: 'Perfil para PyMEs', name: 'PyMEs', description: 'PyMEs', link: '' },
        {image: profileTwo, alt: 'Perfil para Empresas', name: 'Empresas', description: 'Empresas', link: '' },
        {image: profileThree, alt: 'Perfil para Eventos', name: 'Eventos', description: 'Eventos', link: '' },
      ]
    }
  }

  return (
    <Layout>
      <Seo
        lang={'es'}
        title={'Servicios integrales de publicidad para tu negocio'}
        description={"Estamos comprometidos en obtener los resultados que tu negocio necesita, contamos con servicios especializados de branding, publicidad, desarrollo web y marketing digital."}
        keywords={['Servicios,Publicidad,Estrategia,Soluciones,Branding,Diseño,Naming,Logotipo,Brochure,Creativo,Plan,Medios,Campañas,Programación,Código,Posicionamiento,Tráfico,Interfaces,Gestión,Multimedia,Proyecto,Pymes,Empresa,Eventos,Corporativos']}
        noIndex={''}
        noFollow={''}
        slug={'services'}
        canonicalUrl={'https://allency.com.mx/services'}
        ogTitle={'Servicios integrales de publicidad para tu negocio'}
        ogType={'website'}
        ogDescription={"Estamos comprometidos en obtener los resultados que tu negocio necesita, contamos con servicios especializados de branding, publicidad, desarrollo web y marketing digital."}
        ogImage={'../../images/logos/allency-dark.png'}
      />
      
      <SmallHeroBand contents={contents.hero} headerSpace={true} />
      <Services contents={contents.services} />
      <MediumBand bg={theme.colors.extended.gray900} title={`Hagamos de tu <br/> proyecto una realidad`} description={'Soluciones integrales de publicidad'} colorDescription={theme.colors.primary} button={'Comencemos tu proyecto'} buttonVariant={'button-light-ghost'} link={'/contact'}/>
      <Profiles contents={contents.profiles} />
    </Layout>
  )
}

export default IndexPage